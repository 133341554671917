import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.service';
import { RoleType } from './user/RoleType';
import { RoleGuardService } from './auth/role-guard.service';

const appRoutes: Routes = [
  {
    path: 'marketplace',
    loadComponent: () => import('./user/login-sell/register-sell.component').then(rs => rs.RegisterSellComponent)
  },
  {
    path: 'register',
    loadComponent: () => import('./user/registration/registration.component').then(r => r.RegistrationComponent)
  },
  {
    path: 'forgot-password',
    loadComponent: () => import('./user/credentials/forgot-password/forgot-password.component').then(f => f.ForgotPasswordComponent)
  },
  {
    // for delete - not used, investigate
    path: 'reset-password',
    loadComponent: () => import('./user/credentials/reset-password/reset-password.component').then(r => r.ResetPasswordComponent)
  },
  {
    path: 'login',
    loadComponent: () => import('./user/login/login.component').then(l => l.LoginComponent)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard, RoleGuardService],
    data: {roles: [RoleType.SUPER_ADMIN, RoleType.ADMIN, RoleType.REVIEWER, RoleType.VENDOR]}
    // data: {expectedRole: ROLE.ADMIN}},
  },
  {
    path: '', // have to be main module!?
    loadChildren: () => import('./_layout/app-layout.module').then(m => m.AppLayoutModule),
    // canActivate: [AuthGuard]  // some pages are not under auth guard - canActivateChild
    // data: { preload: true }
  },
  {
    path: '**',
    // redirectTo: '/login'
    loadChildren: () => import('./common/page_not_found/page-not-found.component').then(p => p.PageNotFoundComponent)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      // {
      //   enableTracing: true,
      //   preloadingStrategy: SelectivePreloadingStrategyService
      // }
      // Call enableProdMode() to enable the production mode. // - enable production but where to invoke this method!!!
    )
    // RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}

