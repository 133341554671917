import {Injectable} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {AppMessageService} from '../message/app-message.service';
import {convertToErrorEnum, ErrorMessage, getErrorMessage} from '../common/app-error-message/ErrorMessage';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService,
              private messageService: AppMessageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // retry(1), // on exception - try again
      catchError((error: HttpErrorResponse) => {

          let errorMessage = '';
          if (error instanceof HttpErrorResponse) {
            if (error.status === 0) {
              // A client-side or network error occurred. Handle it accordingly.
              errorMessage = error.message;
            } else {
              errorMessage = error.error;
              // server-side error
              if ((
                error.status === 403 ||
                error.status === 405 ||
                error.status === 406)) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                // location.reload();
              }

              const errMessageKey = error.error;
              if (typeof errMessageKey === 'object') {
                for (const err of Object.keys(errMessageKey)) {
                  const value = convertToErrorEnum(err);
                  errorMessage = this.showErrorFromEnum(value);
                }
              }
              if (typeof errMessageKey === 'string') {
                // workaround for native spring exceptions
                let value = convertToErrorEnum(errMessageKey);
                if (typeof value === 'string') {
                  value = error.error;
                }
                if (!value) {
                  value = ErrorMessage.ERROR_MESSAGE_BAD_CREDENTIALS;
                }
                errorMessage = this.showErrorFromEnum(value);
              }
              // errorMessage = error.error;
              // console.error(`Backend returned code ${error.status}, body was: `, error.error);
            }
            // Return an observable with a user-facing error message.
            console.error('An error occurred:', errorMessage);
          }
          return throwError(() => new Error(errorMessage));
        }
      ));
  }

  private showErrorFromEnum(message: ErrorMessage) {
    const showErrorMessage = getErrorMessage(message);
    if (showErrorMessage) {
      this.messageService.warn(showErrorMessage);
      return showErrorMessage;
    }
    return '';
  }

  private handleError(error: HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 0) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        for (const err of Object.keys(error.error)) {
          let v = error.error[err];
          console.error(v);
          this.messageService.info(v);
        }
        console.error(
          `Backend returned code ${error.status}, body was: `, error.error);
      }
      // Return an observable with a user-facing error message.
      return throwError(() => new Error('Something happened; please try again later.'));
    } else {
      console.error('An error occurred:', error);
    }
  }
}
